import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import axios from '../axios-instance'

function EmailVerification({ setLoading, error, setError, setActiveStep, email, setEmail }) {

    const [otp, setOtp] = useState('');
    const [otpRequested, setOtpRequested] = useState(false);

    const validateEmail = (email) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        return pattern.test(email) ? true : false
    }

    const requestOTP = () => {
        setLoading(true)
        if (!validateEmail(email)) {
            setError({
                error: true,
                errorMessage: 'Entered email is not valid',
                errorMessageData: 'Please check and enter again'
            })
            setLoading(false)

        } else {
            // submit email to trigger OTP
            axios.post('/auth/send-otp', { email })
                .then((res) => {
                    if (!res.data.error) {
                        setOtpRequested(true);
                        setError({
                            error: false,
                            errorMessage: '',
                            errorMessageData: ''
                        })
                    }
                    setLoading(false)
                })
                .catch(error => {
                    setError({
                        error: true,
                        errorMessage: 'Error in login. Unable to reach the backend',
                        errorMessageData: error.toString()
                    })
                    setLoading(false)
                })
        }

    }


    const signInByOTP = () => {
        setLoading(true);
        if (isNaN(otp)) {
            setError({
                error: true,
                errorMessage: 'Entered OTP is not valid',
                errorMessageData: 'Please check and enter again',
            });
            setLoading(false);
        } else {
            axios.post('/auth/verify-otp', {
                email,
                otp,
            })
                .then((res) => {
                    console.log(res);
                    if (res?.data?.error) {
                        console.log(res?.data);
                        setError({
                            error: true,
                            errorMessage: res.data.errorMessage,
                            errorMessageData: res.data.errorMessageData,
                        });
                    } else {
                        console.log(res?.data);
                        setActiveStep(2);
                        setError({
                            error: false,
                            errorMessage: "",
                            errorMessageData: '',
                        });
                    }
                    setLoading(false);
                })
                // .then((res) => {
                //     setActiveStep(2)
                //     setLoading(false)
                // })
                .catch((error) => {
                    setError({
                        error: true,
                        errorMessage: 'Error in login. Unable to reach the backend',
                        errorMessageData: error.message,
                    });
                    setLoading(false);
                });
        }
    };


    return (
        <>
            <Form size='large'>
                {
                    error?.error &&
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{error?.errorMessage ? error?.errorMessage : ''}</p>
                        <p>{error?.errorMessageData ? error?.errorMessageData.toString() : ''}</p>
                    </Message>
                }

                {
                    otpRequested ?
                        <p>Please enter code that you recieved to the entered email</p>
                        :
                        <p>Please enter your email address</p>
                }

                <Form.Input
                    fluid icon='user'
                    iconPosition='left'
                    placeholder='E-mail address'
                    onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                    value={email}
                    required
                    disabled={otpRequested}
                />
                {
                    !otpRequested &&
                    <Button onClick={requestOTP} color='blue' fluid size='large' disabled={email === ""}>
                        Verify
                    </Button>
                }
            </Form>
            <br />
            {
                otpRequested &&
                <Form size='large'>

                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Code'
                        onChange={(e) => setOtp(e.target.value.trim())}


                        value={otp}
                        required
                    />
                    <Button onClick={signInByOTP} color='blue' fluid size='large' disabled={otp === ""}>
                        Confirm
                    </Button>
                </Form>
            }
        </>
    )


}

export default EmailVerification;