import { Segment, Message, Icon } from "semantic-ui-react"

const ThankYouMessage = () => {
    return (
        <Segment basic textAlign="center">
            <Message positive>
                <Message.Header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    <Icon name="check circle" color="green" size="large" /> <div>Thank you for participating in the Raffle Draw.</div>
                </Message.Header>
                <p style={{ fontSize: "16px", marginTop: "15px" }}>
                    Tune into Hikvision Sri Lanka Facebook page on <strong>5th November 2023</strong> at <strong>5:00 PM</strong> to watch the raffle draw live.
                </p>
            </Message>
        </Segment>
    )
}

export default ThankYouMessage;


