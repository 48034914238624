import React, { useState } from "react";
import { Button, Icon, Header } from "semantic-ui-react";
import "./SocialMediaButton.css"; 

const SocialMediaButtons = ({handleNext,winningChances,setWinningChances}) => {
    const [facebookLiked, setFacebookLiked] = useState(false);
    const [instagramLiked, setInstagramLiked] = useState(false);
    const [linkedInLiked, setLinkedInLiked] = useState(false);
    const [youtubeSubscribed, setYoutubeSubscribed] = useState(false);

    const socialMediaUrls = {
        facebook: "https://www.facebook.com/HikvisionSriLankaOfficial/",
        instagram: "https://www.instagram.com/hikvisionsrilankaofficial/",
        linkedin: "https://www.linkedin.com/company/75667528/",
        youtube: "https://www.youtube.com/@HikvisionSriLanka",
    };

    const handleButtonClick = (socialMedia) => {
        if (socialMedia === "facebook") {
            if (!facebookLiked) {
                setFacebookLiked(true);
                setWinningChances(winningChances + 1);
                window.open(socialMediaUrls[socialMedia], "_blank");
            } else {
                setFacebookLiked(false);
                setWinningChances(winningChances - 1);
            }
        } else if (socialMedia === "instagram") {
            if (!instagramLiked) {
                setInstagramLiked(true);
                setWinningChances(winningChances + 1);
                window.open(socialMediaUrls[socialMedia], "_blank");
            } else {
                setInstagramLiked(false);
                setWinningChances(winningChances - 1);
            }
        } else if (socialMedia === "linkedin") {
            if (!linkedInLiked) {
                setLinkedInLiked(true);
                setWinningChances(winningChances + 1);
                window.open(socialMediaUrls[socialMedia], "_blank");
            } else {
                setLinkedInLiked(false);
                setWinningChances(winningChances - 1);
            }
        } else if (socialMedia === "youtube") {
            if (!youtubeSubscribed) {
                setYoutubeSubscribed(true);
                setWinningChances(winningChances + 1);
                window.open(socialMediaUrls[socialMedia], "_blank");
            } else {
                setYoutubeSubscribed(false);
                setWinningChances(winningChances - 1);
            }
        }
    };

    return (
        <div className="social-media-buttons-container">
            <Header as="h3">Winning Chances: {winningChances}</Header>
            <div className="social-media-button">
                <Button
                    fluid
                    color={facebookLiked ? "blue" : "facebook"}
                    onClick={() => handleButtonClick("facebook")}
                >
                    <Icon name={facebookLiked ? "thumbs up" : "facebook"} />
                    {facebookLiked ? "Liked" : "Like Us On Facebook"}
                </Button>
            </div>
            <div className="social-media-button">
                <Button
                    fluid
                    color={instagramLiked ? "purple" : "instagram"}
                    onClick={() => handleButtonClick("instagram")}
                >
                    <Icon name={instagramLiked ? "heart" : "instagram"} />
                    {instagramLiked ? "Followed" : "Follow Us On Instagram"}
                </Button>
            </div>
            <div className="social-media-button">
                <Button
                    fluid
                    color={linkedInLiked ? "blue" : "linkedin"}
                    onClick={() => handleButtonClick("linkedin")}
                >
                    <Icon name={linkedInLiked ? "linkedin" : "thumbs up outline"} />
                    {linkedInLiked ? "Followed" : "Follow Us On LinkedIn"}
                </Button>
            </div>
            <div className="social-media-button">
                <Button
                    fluid
                    color={youtubeSubscribed ? "red" : "youtube"}
                    onClick={() => handleButtonClick("youtube")}
                >
                    <Icon name={youtubeSubscribed ? "check" : "youtube"} />
                    {youtubeSubscribed ? "Subscribed" : "Subscribe to Our YouTube"}
                </Button>
            </div>
        </div>
        
    );
};

export default SocialMediaButtons;