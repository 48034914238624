import { useState } from "react";
import { Container, Header, Segment, Image, Step, Grid } from "semantic-ui-react";
import EmailVerification from "./components/EmailVerification";
import UserDetailsForm from "./components/UserDetailsForm";
import CompanyDetails from "./components/CompanyDetails";
import SocialMediaEngagement from "./components/SocialMediaEngagement";
import raffleDrawImage from './resourses/raffledrawImage.png';
import hikvisionLogo from './resourses/logo.png';
import ThankYouMessage from "./components/ThankYouMessage";
import SubmitPage from "./components/SubmitPage";

const App = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    error: false,
    errorMessage: '',
    errorMessageData: ''
  })
  const [activeStep, setActiveStep] = useState(1);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [selectedProvince, setSelectedProvince] = useState("")
  const [address, setAddress] = useState('');
  const [representCompany, setRepresentCompany] = useState(false);
  const [companyType, setCompanyType] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [winningChances, setWinningChances] = useState(1)


  return (
    <Container>
      <Segment basic loading={loading}>
        <Image size='medium' src={raffleDrawImage} centered />
        {activeStep !== 6 &&
          <Header as='h1' textAlign='center' style={{ marginTop: '16px', fontSize: '28px', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
            Enter your details to enroll for the raffle draw
          </Header>
        }

        <>
          {activeStep !== 6 ?
            <>
              <Step.Group ordered fluid attached='top' size="tiny">
                <Step active={activeStep === 1} completed={activeStep !== 1} disabled={activeStep !== 1}>
                  <Step.Content>
                    <Step.Title>Verify your email</Step.Title>
                    <Step.Description>Validate your eligibility for victory</Step.Description>
                  </Step.Content>
                </Step>

                <Step active={activeStep === 2} completed={activeStep !== 2 && activeStep !== 1} disabled={activeStep !== 2}>
                  <Step.Content>
                    <Step.Title>Enter your contact details</Step.Title>
                    <Step.Description>Enable us to reach out to you</Step.Description>
                  </Step.Content>
                </Step>

                <Step active={activeStep === 3} completed={activeStep !== 3 && activeStep !== 1 && activeStep !== 2} disabled={activeStep !== 3} >
                  <Step.Content>
                    <Step.Title>Enter your company details</Step.Title>
                    <Step.Description>Help us obtain your company information</Step.Description>
                  </Step.Content>
                </Step>
                <Step active={activeStep === 4} completed={activeStep !== 4 && activeStep !== 1 && activeStep !== 2 && activeStep !== 3} disabled={activeStep !== 4}>
                  <Step.Content>
                    <Step.Title>Like and Follow Hikvision Sri Lanka</Step.Title>
                    <Step.Description>Increase your winning chances</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>

              <Segment attached>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column style={{ maxWidth: 450 }}>
                      {activeStep === 1 &&
                        <EmailVerification
                          setLoading={setLoading}
                          setError={setError}
                          error={error}
                          setActiveStep={setActiveStep}
                          email={email}
                          setEmail={setEmail} />}
                      {activeStep === 2 &&
                        <UserDetailsForm
                          setLoading={setLoading}
                          setError={setError}
                          setActiveStep={setActiveStep}
                          firstName={firstName}
                          setFirstName={setFirstName}
                          lastName={lastName}
                          setLastName={setLastName}
                          contactNumber={contactNumber}
                          setContactNumber={setContactNumber}
                          selectedProvince={selectedProvince}
                          setSelectedProvince={setSelectedProvince}
                          address={address}
                          setAddress={setAddress} />}
                      {activeStep === 3 &&
                        <CompanyDetails
                          setLoading={setLoading}
                          setError={setError}
                          setActiveStep={setActiveStep}
                          representCompany={representCompany}
                          setRepresentCompany={setRepresentCompany}
                          companyType={companyType}
                          setCompanyType={setCompanyType}
                          companyName={companyName}
                          setCompanyName={setCompanyName} />}
                      {activeStep === 4 &&
                        <SocialMediaEngagement
                          setLoading={setLoading}
                          setError={setError}
                          setActiveStep={setActiveStep}
                          error={error}
                          winningChances={winningChances}
                          setWinningChances={setWinningChances} />}
                      {activeStep === 5 &&
                        <SubmitPage
                          setLoading={setLoading}
                          setError={setError}
                          setActiveStep={setActiveStep}
                          error={error}
                          email={email}
                          firstName={firstName}
                          lastName={lastName}
                          contactNumber={contactNumber}
                          selectedProvince={selectedProvince}
                          address={address}
                          representCompany={representCompany}
                          companyType={companyType}
                          companyName={companyName}
                          winningChances={winningChances}
                        />}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </>

            :
            <div style={{ marginTop: '100px', marginBottom: '100px' }}>
              <ThankYouMessage />
            </div>
          }
        </>

        <Segment basic textAlign="center">
          <Image size='small' src={hikvisionLogo} centered />
          <p style={{ color: 'grey', marginTop: '5px' }}>©2023 - Hikvision Sri Lanka</p>
        </Segment>
      </Segment>
    </Container>
  )
}


export default App;

