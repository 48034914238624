import { Header, Message, Button, Container, Form } from "semantic-ui-react"
import SocialMediaButtons from "./SocialMediaButtons"

const SocialMediaEngagement = ({ error, setActiveStep, winningChances, setWinningChances }) => {


    const handleNext = () => {
        setActiveStep(5)
    };


    return (
        <Container>
            <Header textAlign='center'>Social Media Engagement</Header>
            <Form>
                {
                    error?.error &&
                    <Message negative>
                        <Message.Header>Error</Message.Header>
                        <p>{error?.errorMessage ? error?.errorMessage : ''}</p>
                        <p>{error?.errorMessageData ? error?.errorMessageData.toString() : ''}</p>
                    </Message>
                }
                <Form.Field>
                    <SocialMediaButtons handleNext={handleNext} winningChances={winningChances} setWinningChances={setWinningChances} />
                </Form.Field>
                <Form.Field>
                    <Button
                        primary
                        onClick={handleNext}
                        floated="right"
                    >
                        Next
                    </Button>
                </Form.Field>


            </Form>
        </Container>

    )
}

export default SocialMediaEngagement