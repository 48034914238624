import React, { useState } from 'react';
import { Container, Form, Button, Header, Radio, Dropdown, Message } from 'semantic-ui-react';

const CompanyDetails = ({ setActiveStep, representCompany, setRepresentCompany, companyType, setCompanyType, companyName, setCompanyName }) => {


    const [errors, setErrors] = useState({});

    // const handleNext =()=>{
    //     setActiveStep(4)
    // }
    const companyTypes = [
        { key: "si", text: 'System Intergrator', value: "si" },
        { key: "dpp", text: ' Authorized Reseller - DPP', value: "dpp" },
        { key: "inst", text: 'Installer - SSP', value: "inst" },
        { key: "ec", text: 'End Customer', value: "ec" }
    ]

    const handleNext = () => {
        // Validation checks
        const newErrors = {};


        if (representCompany) {
            if (!companyType) {
                newErrors.companyType = 'Company type is required';
            }
            if (!companyName) {
                newErrors.companyName = 'Company name is required';
            }
        }

        if (Object.keys(newErrors).length === 0) {
            setActiveStep(4);
        } else {
            setErrors(newErrors);
        }
    };

    const clearError = (field) => {
        setErrors({ ...errors, [field]: '' });
    };

    return (
        <Container>
            <Header textAlign='center'>Company Details</Header>
            <Form>
                <Form.Field>
                    <label>Do you represent a company?</label>
                    <Form.Field>
                        <Radio
                            label="Yes"
                            name="companyRadio"
                            checked={representCompany}
                            onChange={() => setRepresentCompany(true)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="No"
                            name="companyRadio"
                            checked={!representCompany}
                            onChange={() => setRepresentCompany(false)}
                        />
                    </Form.Field>
                </Form.Field>
                {representCompany && (
                    <>
                        <Form.Field>
                            <label>Company Type</label>
                            <Dropdown
                                placeholder="Select your Company Type"
                                fluid
                                selection
                                options={companyTypes}
                                value={companyType}
                                onChange={(e, { value }) => {
                                    setCompanyType(value);
                                    clearError('companyType');
                                }}
                            />
                            {errors.companyType && <Message color="red" content={errors.companyType} />}
                        </Form.Field>

                        <Form.Field>
                            <label>Company Name</label>
                            <input
                                type="text"
                                placeholder="Enter your company name"
                                value={companyName}
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                    clearError('companyName');
                                }}
                            />
                            {errors.companyName && <Message color="red" content={errors.companyName} />}
                        </Form.Field>
                    </>
                )}
                <Button
                    disabled={representCompany === null || (representCompany && (!companyType || !companyName))}
                    primary
                    onClick={handleNext}
                    floated="right"
                >
                    Next
                </Button>
            </Form>
        </Container>
    );

}

export default CompanyDetails