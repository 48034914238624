import React, { useState } from 'react';
import { Container, Form, Button, Dropdown, Message, Header } from 'semantic-ui-react';


function UserDetailsForm({ setActiveStep, firstName, setFirstName, lastName, setLastName, contactNumber, setContactNumber, address, setAddress, selectedProvince, setSelectedProvince }) {

    const [errors, setErrors] = useState({});

    const handleNext = () => {
        // Validation checks
        const newErrors = {};

        if (!firstName) {
            newErrors.firstName = 'First name is required';
        }
        if (!lastName) {
            newErrors.lastName = 'Last name is required';
        }
        if (!contactNumber) {
            newErrors.contactNumber = 'Contact number is required';
        } else if (!/^\d{10}$/.test(contactNumber)) {
            newErrors.contactNumber = 'Please enter a valid 10-digit mobile number.';
        }
        if (!selectedProvince) {
            newErrors.selectedProvince = 'Province is required';
        }
        if (!address) {
            newErrors.address = 'Address is required';
        }


        if (Object.keys(newErrors).length === 0) {
            setActiveStep(3);
        } else {
            setErrors(newErrors);
        }
    };

    const clearError = (field) => {
        setErrors({ ...errors, [field]: '' });
    };

    const provinceOptions = [
        { key: "southern", text: 'Southern', value: "southern" },
        { key: "western", text: 'Western', value: "western" },
        { key: "central", text: 'Central', value: "central" },
        { key: "sabaragamuwa", text: 'Sabaragamuwa', value: "sabaragamuwa" },
        { key: "uva", text: 'Uva', value: "uva" },
        { key: "northCentral", text: 'North Central', value: "northCentral" },
        { key: "northWestern", text: 'North Western', value: "northWestern" },
        { key: "eastern", text: 'Eastern', value: "eastern" },
        { key: "northern", text: 'Northern', value: "northern" }
    ]


    return (
        <Container>
            <Header textAlign='center'>User Details</Header>
            <Form>
                <Form.Field>
                    <label>First Name</label>
                    <input
                        type="text"
                        placeholder="Enter Your First Name"
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                            clearError('firstName');
                        }}
                    />
                    {errors.firstName && <Message color="red" content={errors.firstName} />}
                </Form.Field>

                <Form.Field>
                    <label>Last Name</label>
                    <input
                        type="text"
                        placeholder="Enter Your Last Name"
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                            clearError('lastName');
                        }}
                    />
                    {errors.lastName && <Message color="red" content={errors.lastName} />}
                </Form.Field>
                <Form.Field>
                    <label>Contact Number</label>
                    <input
                        type="tel"
                        placeholder="Enter your contact number"
                        value={contactNumber}
                        onChange={(e) => {
                            setContactNumber(e.target.value);
                            clearError('contactNumber');
                        }}
                    />
                    {errors.contactNumber && <Message color="red" content={errors.contactNumber} />}
                </Form.Field>
                <Form.Field>
                    <label>Province</label>
                    <Dropdown
                        placeholder='Select your Province'
                        fluid
                        selection
                        options={provinceOptions}
                        value={selectedProvince}
                        onChange={(e, { value }) => {
                            setSelectedProvince(value);
                            clearError('selectedProvince');
                        }}
                    />
                    {errors.selectedProvince && <Message color="red" content={errors.selectedProvince} />}
                </Form.Field>
                <Form.Field>
                    <label>Address</label>
                    <textarea
                        placeholder="Enter your address"
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                            clearError('address');
                        }}
                    />
                    {errors.address && <Message color="red" content={errors.address} />}
                </Form.Field>
                <Button
                    disabled={
                        !(
                            firstName &&
                            lastName &&
                            contactNumber &&
                            address &&
                            selectedProvince
                        )
                    }
                    primary
                    onClick={handleNext}
                    floated="right"
                >
                    Next
                </Button>
            </Form>
        </Container>
    );
}

export default UserDetailsForm;