import React from 'react';
import { Form, Container, Button, Header, Message, Segment } from 'semantic-ui-react';
import axios from '../axios-instance';


const SubmitPage = ({
    setLoading,
    setError,
    error,
    setActiveStep,
    email,
    firstName,
    lastName,
    contactNumber,
    address,
    representCompany,
    companyType,
    companyName,
    selectedProvince,
    winningChances,
}) => {
    const submitforRaffleDraw = () => {
        setLoading(true);

        axios
            .post('/user/add-details', {
                email,
                firstName,
                lastName,
                contactNumber,
                address,
                representCompany,
                province: selectedProvince,
                companyType,
                companyName,
                registered: true,
                winningChances,
            })
            .then((res) => {
                if (!res.data.error) {
                    setActiveStep(6);
                    setError({
                        error: false,
                        errorMessage: "",
                        errorMessageData: '',
                    });
                }
                setLoading(false);
            })
            .catch((error) => {
                setError({
                    error: true,
                    errorMessage: 'Error in login. Unable to reach the backend',
                    errorMessageData: error.toString(),
                });
                setLoading(false);
            });
    };

    return (
        <Container >
            <Header textAlign="center">Your Details</Header>
            <Segment>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>First Name</label>
                            <input placeholder="First Name" value={firstName} readOnly disabled />
                        </Form.Field>
                        <Form.Field>
                            <label>Last Name</label>
                            <input placeholder="Last Name" value={lastName} readOnly disabled />
                        </Form.Field>
                    </Form.Group>

                    <Form.Field>
                        <label>Email</label>
                        <input placeholder="Email" value={email} readOnly disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Contact Number</label>
                        <input placeholder="Contact Number" value={contactNumber} readOnly disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Address</label>
                        <textarea placeholder="Address" value={address} readOnly disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Province</label>
                        <input placeholder="Province" value={selectedProvince} readOnly disabled />
                    </Form.Field>
                    <Form.Field>
                        <label>Represent Company</label>
                        <input
                            placeholder="Represent Company"
                            value={representCompany ? 'Yes' : 'No'}
                            readOnly
                            disabled
                        />
                    </Form.Field>
                    {representCompany && (
                        <div>
                            <Form.Field>
                                <label>Company Type</label>
                                <input
                                    placeholder="Company Type"
                                    value={
                                        companyType === 'dsi' ? 'Direct System Integrator' :
                                            companyType === 'si' ? 'System Integrator' :
                                                companyType === 'dpp' ? 'Authorized Reseller - DPP' :
                                                    companyType === 'inst' ? 'Installer - SSP' :
                                                        companyType === 'ec' ? 'End Customer' : ''
                                    }
                                    readOnly
                                    disabled
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Company Name</label>
                                <input placeholder="Company Name" value={companyName} readOnly disabled />
                            </Form.Field>
                        </div>
                    )}
                    <Form.Field>
                        <label>Winning Chances</label>
                        <input placeholder="Winning Chances" value={winningChances} readOnly disabled />
                    </Form.Field>
                    {error.error && (
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{error.errorMessage}</p>
                            <p>{error.errorMessageData}</p>
                        </Message>
                    )}
                    <Button
                        fluid
                        color="green"
                        onClick={submitforRaffleDraw}
                    >
                        Enter to the Raffle Draw
                    </Button>
                </Form>
            </Segment>

            {/* {error.error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error.errorMessage}</p>
          <p>{error.errorMessageData}</p>
        </Message>
      )} */}
        </Container>
    );
};

export default SubmitPage;
